import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image'

import Layout from '../components/layout';
import SEO from '../components/seo'

import { ContentWrapper } from '../components/Elements'

import { Grid, Header, Segment } from 'semantic-ui-react'

import CTASection from '../components/CTA Section';
import Lightbox from '../components/Lightbox'

const galleryTemplate = ({ data }) => {

  const { galleryImages, heroImage: { fixed }, heroImagePosition, nameOfGallery } = data.datoCmsGallery

  return (
    <Layout>
      <SEO title={ nameOfGallery } />    
       <Segment basic style={{ 
          minHeight: '50vh'
         }} vertical textAlign='center'>
        <Img 
            fixed={ fixed }
            imgStyle={{ objectPosition: 'center ' + heroImagePosition }}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              zIndex: -1,
            }}
           />
          <Header as='h2' 
            content={ nameOfGallery }
            inverted
            style={{
              fontFamily: 'Bangers',
              fontSize: '3.5rem',
              fontWeight: 'normal',
              textShadow: '1px 1px 6px rgba(100, 100, 100, 0.7)',
              position: 'absolute',
              bottom: '4rem',
              width: '100%'
            }}
           />
      </Segment>
      <Grid centered>
        <Grid.Row>
          <Grid.Column largeScreen={10} computer={12} tablet={12} mobile={12}>
          <ContentWrapper>
            <Lightbox images={ galleryImages } />
            </ContentWrapper>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <CTASection />
    </Layout>
  );
};

export default galleryTemplate;

export const pageQuery = graphql`
  query GalleryBySlug($uid: String!) {

    datoCmsGallery(uid: { eq: $uid }) {
      uid
      nameOfGallery
      shortDescription
      heroImage {
        fixed(width: 900, height: 460) {
          ...GatsbyDatoCmsFixed
        }
      }
      heroImagePosition
      galleryImages {
        thumbnail: fixed(width: 300, height: 300) {
          ...GatsbyDatoCmsFixed
        }
        large: fluid(maxWidth: 900, maxHeight: 460) {
        ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
